@import "/src/_styleguide/variables.scss";

.admin-wizard-credit {
  .experian-button {
    display: flex;
    justify-content: center;
    margin-bottom: 32px;
    color: #000;

    font-family: $font-title;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    img {
      margin-left: 8px;
      height: 22px;
    }
  }
  .credit-confirmation {
    margin-bottom: 32px;
  }
}
