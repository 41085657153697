@import "/src/_styleguide/variables.scss";

.task-templates {
  .task-title {
    display: flex;
    align-items: center;
    margin: 0 0 16px 0;
    h3 {
      margin: 0 !important;
      color: #000;
      font-family: $font-title;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      flex: 1;
    }
    button {
      font-size: 12px;
      font-weight: 500 !important;
      svg {
        width: 12px;
        height: 12px;
        color: #757575 !important;
      }
    }
  }
  .tt-empty {
    padding: 4px 12px;
    font-size: 13px;
    color: #757575;
    font-style: italic;
  }
  .pp-search {
    margin-bottom: 16px;
  }
  .template-item {
    padding: 12px 16px;
    border-radius: 8px;
    background: #fff;
    color: #000;
    font-family: $font-title;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    position: relative;
    svg {
      display: none;
      width: 14px;
      height: 14px;
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      color: #757575;
    }
    &:hover {
      background: #f7f7f7;
      svg {
        display: block;
      }
    }
  }
  .ant-collapse {
    border: 0 !important;
    background: transparent !important;
    .ant-collapse-item {
      border: 0 !important;
      margin-bottom: 16px;
    }
    .ant-collapse-content {
      border: 0 !important;
      .ant-collapse-content-box {
        padding: 4px 0 0 0;
      }
    }
    .ant-collapse-header {
      padding: 4px 8px;
      align-items: center;
      align-self: stretch;
      border-radius: 8px !important;
      background: #f3f3f3;
      border: 0 !important;
      color: #000;
      font-family: $font-title;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 160% */
      letter-spacing: 0.2px;
      text-transform: uppercase;
      .ant-collapse-expand-icon {
        padding-inline-end: 8px !important;
        svg {
          font-size: 10px;
        }
      }
    }
  }
}
