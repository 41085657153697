@import "/src/_styleguide/variables.scss";
.tc-container {
  .task-card {
    display: flex;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-radius: 16px;
    border: 1px solid #ececec;
    background: #fff;
    box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, 0.03);
    width: 360px;
    max-width: 360px;
    position: relative;
    margin-bottom: 0;
    .tc-actions {
      display: none;
      flex-direction: row;
      align-items: center;
      gap: 6px;
      position: absolute;
      top: 12px;
      right: 12px;
      button {
        font-weight: 500 !important;
        padding: 8px 6px !important;
      }
      button.btn-nope svg {
        stroke-width: 1.5 !important;
      }
    }

    &:hover .tc-actions {
      display: flex;
    }
    .tc-header {
      display: flex;
      gap: 10px;
      align-items: center;
      h4 {
        margin: 0 !important;
        color: #000;
        font-family: $font-title;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 146.667% */
      }
    }
    > p {
      margin: 0 !important;
      color: #737272;
      font-family: $font-title;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 150% */
    }
    .tc-info {
      display: flex;
      gap: 6px;
      align-items: center;
      .pp-tag {
        display: flex;
        align-items: center;
        gap: 4px;
        color: #000;
        font-family: $font-title;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        border-radius: 8px;
        svg {
          width: 14px;
          height: 14px;
          color: #9d9d9d;
        }
      }
    }
  }

  .tc-spacer {
    padding: 8px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    > div {
      display: block;
      width: 2px;
      height: 32px;
      border: 1px #ededec;
      background: #d9d9d9;
    }
  }
  &.dragging .tc-spacer {
    display: none !important;
  }
}
