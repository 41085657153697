@import "/src/_styleguide/variables.scss";

.admin-workflows {
  display: flex;
  height: 100%;
  max-height: 100%;
  width: 100%;
  position: absolute;
  .task-templates {
    width: 266px;
    min-width: 266px;
    border-right: 1px solid #ececec;
    padding: 20px 12px;
    background: #fff;
  }
  .workflows {
    flex: 1;
    padding: 24px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    table {
      width: auto;
    }
    .ant-pagination {
      margin: 0 -40px;
      align-items: center;
      justify-content: flex-end;
      background: #fff;
      border-top: 1px solid #ededed;
      padding: 16px 72px 16px 140px;
      position: fixed;
      bottom: 0;
      width: 100%;
      right: 0;
      width: calc(100% - 200px - 266px);
      transition: width 0.2s;
    }
    .manage-search {
      display: flex;
      padding: 10px 12px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      border-radius: 10px 10px 0px 0px;
      border-top: 1px solid #ececec;

      border-right: 1px solid #ececec;

      border-left: 1px solid #ececec;

      background: #fff;
      button {
        font-family: $font-title;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;
        border-radius: 10px !important;
        svg {
          width: 14px;
          height: 14px;
          color: #757575 !important;
        }
      }
      .pp-search .ant-input-search-button {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
      }
      .pp-search input {
        line-height: 26px;
      }
      .pp-search.ant-input-search .ant-input-search-button {
        height: 40px;
        width: 40px;
      }
    }
    .workflow-actions button.barn-btn.btn-tiny {
      font-weight: 500 !important;
    }
    .workflow-actions svg {
      stroke-width: 1.5;
    }
    .workflow-name {
      color: #000;
      font-family: $font-title;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
      margin-bottom: 2px;
    }
    .helper.workflow-helper {
      color: #757575;
      font-family: $font-title;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 19px; /* 146.154% */
    }
  }
  .workflows-top {
    display: flex;
    gap: 12px;
    align-items: flex-start;
    margin-bottom: 24px;
    .wf-icon {
      display: flex;
      width: 36px;
      height: 36px;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      background: #ececec;
      svg {
        color: #000;
        width: 20px;
        height: 20px;
      }
    }
    .wf-info {
      h2 {
        margin: 0 !important;
        color: #000;
        font-family: $font-title;
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: 38px; /* 135.714% */
      }
      p {
        margin: 0 !important;
        color: #757575;
        font-family: $font-title;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px; /* 150% */
      }
    }
  }
}
