@import "/src/~@centrate-io/barn/dist/variables";

.settings-users {
  svg.tabler-icon-circle-check {
    color: $success;
  }
  svg.tabler-icon-circle-minus {
    color: $dark-200;
  }
  svg.tabler-icon-circle-x {
    color: $error;
  }
  .admin-button {
    font-size: 14px;
  }
  .doc-user {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
  .doc-owner {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    i {
      font-size: 13px;
    }
  }
  .doc-download {
    &:hover {
      text-decoration: underline;
    }
  }
  .role-column {
    text-transform: capitalize;
  }
  .ant-pagination-options {
    display: none !important;
  }
  .ant-pagination {
    margin: 0 !important;
    padding-top: 18px;
  }
}
