@import "/src/_styleguide/variables.scss";

.task-drawer {
  border-radius: 16px;
  overflow: hidden;
  .ant-drawer-header {
    display: none !important;
  }
  .ant-drawer-body {
    padding: 0;
    position: relative;
    .new-task-title {
      display: flex;
      padding: 16px 24px 16px 24px;
      width: calc(100% - 48px);
      align-items: center;
      background: #fff;
      gap: 8px;
      align-self: stretch;
      border-bottom: 1px solid #ececec;
      color: #000;
      font-family: $font-title;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 27px;
      position: absolute;
      top: 0;
      left: 0;
    }
    .new-task-form {
      height: calc(100% - 60px - 73px);
      margin-top: 60px;
      overflow-y: auto;
      padding: 24px 28px;
      display: flex;
      flex-direction: column;
      gap: 24px;
      input {
        font-size: 14px;
      }
      textarea {
        min-height: 160px;
      }
      .ant-select-selection-item {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 14px;
      }
    }
    .new-task-actions {
      display: flex;
      padding: 16px;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      justify-content: space-between;
      margin: 0 !important;
      position: absolute;
      bottom: 0;
      left: 0;
      width: calc(100% - 32px);
      border-top: 1px solid #ececec;
      background: #fff;
      button {
        font-weight: 500 !important;
        font-family: $font-title;
        font-size: 16px;
        font-style: normal;
        line-height: normal;
        svg {
          stroke-width: 1.5;
          width: 20px;
          height: 20px;
        }
      }
      > div {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
  }
}

.ppsd-task .ant-select-item-option-content {
  display: flex;
  align-items: center;
  color: #000;
  .pp-task-badge {
    margin-right: 8px;
  }
}

.task-parent {
  .ant-drawer-content-wrapper {
    border-radius: 16px;
    height: calc(100% - 24px);
    top: 12px;
    right: 12px;
  }
}

.task-parent.ant-drawer.ant-drawer-open {
  z-index: 1001 !important;
}
