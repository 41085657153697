.admin-tabs {
  .ant-tabs-nav {
    margin-left: -32px;
    margin-right: -32px;
    padding-left: 32px;
    padding-right: 32px;
    background: #fff;
  }
  .ant-tabs {
    &.information,
    &.tasks,
    &.notes,
    &.activity {
      .ant-tabs-nav {
        margin-bottom: 0;
      }
      .ant-tabs-content-holder {
        position: absolute;
        top: 46px;
        height: calc(100vh - 106px);
        width: calc(100vw - 240px);
        overflow-y: scroll;
        margin-left: -32px;
        margin-right: -32px;
        padding: 32px;
      }
    }
  }
  .admin-button.modified {
    right: 80px !important;
  }
}
