@import "/src/_styleguide/variables.scss";

.edit-workflow {
  display: flex;
  flex-direction: column;
  align-items: center;
  .workflow-body {
    width: 100%;
    max-width: calc(100% - 266px);
    overflow-y: scroll;
    position: absolute;
    right: 0;
    top: 60px;
    height: calc(100% - 60px);
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .all-tasks {
    width: 360px;
    margin-left: auto;
    margin-right: auto;
    button.btn-secondary svg {
      width: 16px;
      height: 16px;
      color: #757575;
    }
  }
  .tsk-btn svg {
    width: 16px;
    height: 16px;
    color: #757575;
  }
  .no-tasks {
    display: flex;
    width: 360px;
    padding: 16px;
    flex-direction: column;
    align-items: center;
    border-radius: 16px;
    border: 1px solid #ececec;
    background: #fff;
    h3 {
      margin: 0 0 8px 0 !important;
      color: #000;
      text-align: center;
      font-family: $font-title;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 30px;
    }
    p {
      margin: 0 0 24px 0 !important;
      color: #757575;
      text-align: center;
      font-family: $font-title;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .task-templates {
    width: 266px;
    min-width: 266px;
    border-right: 1px solid #ececec;
    padding: 20px 12px;
    background: #fff;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
  }
}
