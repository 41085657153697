@import "/src/~@centrate-io/barn/dist/variables";
@import "/src/_styleguide/variables.scss";

.layout-admin {
  min-height: 100%;

  .admin-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 64px;
  }
  .admin-task-count {
    display: inline-flex;
    padding: 0 8px;
    justify-content: center;
    align-items: center;
    height: 22px;
    border-radius: 8px;
    border: 1px solid #f2a8dd;
    background: #fce4f6;
    color: #96076e;
    font-family: $font-title;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 10px;
    position: relative;
    top: -1px;
  }
  .admin-search {
    position: fixed;
    top: 13px;
    right: 32px;
    z-index: 999;
    &.admin-search-row {
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      height: 60px;
      gap: 12px;
    }
    &.admin-button {
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      height: 60px;
      .is-edited {
        margin-right: 12px;
        color: $dark-400;
        font-style: italic;
      }
    }
    .admin-search-sep {
      display: block;
      width: 1px;
      height: 33%;
      background: #dddddd;
      content: "";
      clear: both;
      margin: 0 12px;
    }
  }
  .admin-bar {
    height: 60px;
    &.admin-search-row {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 12px;
    }
    &.admin-button {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 60px;
      .is-edited {
        margin-right: 12px;
        color: $dark-400;
        font-style: italic;
      }
    }
    .admin-search-sep {
      display: block;
      width: 1px;
      height: 33%;
      background: #dddddd;
      content: "";
      clear: both;
      margin: 0 12px;
    }
  }
  .wizard-btn {
    display: flex;
    align-items: center;
    width: calc(100% - 32px);
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 8px;
    gap: 12px;
    > span {
      font-weight: 600;
      line-height: 14px;
      position: relative;
      top: 1px;
    }
    svg {
      width: 16px;
      height: 16px;
      color: #8f8f8f;
    }
  }

  .ant-menu {
    padding: 0 4px;
    border-right: 0 !important;
    > .ant-menu-item {
      padding: 0 16px !important;
      transition: none !important;
      .ant-menu-title-content,
      .ant-menu-title-content a,
      svg {
        transition: none !important;
      }
      &:hover {
        background: #ededec !important;
      }
    }
  }
  .ant-menu-item > svg {
    display: inline-flex;
    align-items: center;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    width: 18px;
    height: 18px;
    stroke-width: 1.5;
  }
  .ant-menu-item > .ant-menu-item-icon {
    width: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-menu-item.ant-menu-item-selected,
  .ant-menu-item:not(.ant-menu-item-selected):active,
  .ant-menu-item:not(.ant-menu-item-selected):focus {
    background: #ededec !important;
    background-color: #ededec !important;
    color: $dark-1000 !important;
    .ant-menu-title-content,
    svg {
      color: $dark-1000 !important;
    }
  }
  .ant-menu-item.ant-menu-item-selected {
    .ant-menu-title-content {
      font-weight: 500 !important;
      color: #000;
    }
    svg {
      stroke-width: 2 !important;
      color: #000;
    }
  }
  .ant-menu-item .ant-menu-title-content {
    font-weight: 400;
  }
  .ant-menu.ant-menu-light {
    background: #fafaf9;
  }
  .ant-menu-inline-collapsed .ant-menu-item svg {
    position: relative;
    top: 11px;
    left: 5px;
  }
  .ant-layout-sider {
    overflow: auto;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 1000;
    background: #fafaf9;
    border-right: 1px solid #eeedec;
  }
  .ant-layout-sider-trigger {
    height: 50px;
    background: #fafaf9 !important;
    color: $dark-1000;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 8px 8px 8px;
    width: calc(240px - 16px) !important;
    left: 0;
    border-radius: 8px;
    &:hover {
      background: $dark-100 !important;
    }
    .anticon {
      vertical-align: middle;
      display: block;
      svg {
        font-size: 12px;
        font-weight: 600;
      }
    }
  }
  .ant-layout-sider-collapsed .ant-layout-sider-trigger {
    width: calc(80px - 16px) !important;
  }
  .ant-layout-sider-collapsed {
    .wizard-btn {
      padding-left: 12px;
      padding-right: 12px;
      justify-content: center;
      > span {
        display: none;
      }
      svg {
        color: #fff;
      }
    }
  }

  .site-layout {
    background: #fff !important;
    transition: margin 0.2s;
    margin-left: 240px;
    .ant-breadcrumb {
      background: #fff;
      border-bottom: 1px solid #f4efe6;
      position: fixed;
      top: 0;
      right: 0;
      width: 100%;
      height: 64px;
      line-height: 64px;
      padding: 0 40px 0 290px;
      z-index: 99;
    }
    &.collapsed {
      margin-left: 80px;
      .ant-breadcrumb {
        padding: 0 40px 0 120px;
        transition: padding 0.2s;
      }
    }
    .site-content {
      margin: 0;
      .admin-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 32px;
        height: 60px;
        min-height: 60px;
        border-bottom: 1px solid #ededed;
        background: #fafaf9;
        position: fixed;
        top: 0;
        right: 0;
        width: calc(100% - 240px);
        z-index: 999;
        transition: width 0.2s;
        &.wizard {
          display: none !important;
        }
        .crumbs {
          flex: 1;
          p {
            color: $dark-400;
            font-size: 12px;
            line-height: 16px;
            .cp {
              padding-left: 24px;
            }
          }
          h5 {
            display: flex;
            align-items: center;
            font-size: 16px;
            svg {
              width: 18px;
              height: 18px;
              position: relative;
              top: -1px;
              margin-right: 6px;
            }
          }
          .current-count {
            margin-left: 6px;
            color: $dark-400;
            font-size: 14px;
            line-height: 16px;
            font-weight: 400;
          }
        }
        .actions {
          .sign-out {
            padding: 12px 16px;
            border-radius: 12px;
            &:hover {
              background: #d7dce4;
              cursor: pointer;
            }
          }
        }
      }
      .admin-body {
        height: calc(100vh);
        padding: 85px 32px 32px 32px;
        position: relative;
        background: #fafaf9;
      }
      &.sheet .admin-body {
        height: calc(100vh - 65px);
        padding: 85px 32px 32px 32px;
      }
      &.workflows {
        .admin-body {
          overflow: hidden;
          width: calc(100%);
          max-height: calc(100vh);
          padding: 0;
          top: 0;
        }
        .admin-header {
          display: none !important;
        }
      }
      &.analytics {
        .admin-body {
          overflow: hidden;
          width: calc(100%);
          max-height: calc(100vh);
          padding: 0;
          top: 0;
        }
        .admin-header {
          width: calc(100% - 480px);
        }
      }
      &.wizard {
        .admin-body {
          overflow-y: scroll;
          width: calc(100%);
          max-height: calc(100vh - 148px);
          padding: 40px 288px 40px 288px;
          top: 60px;
        }
        .admin-header {
          display: none !important;
        }
      }
      &.edit-workflow {
        .admin-body {
          overflow-y: hidden;
          width: calc(100%);
          max-height: calc(100vh);
          padding: 0;
          top: 0px;
        }
        .admin-header {
          display: none !important;
        }
      }
    }
  }
  .barn-text.helper {
    font-size: 12px;
    color: $dark-600;
  }
  .admin-table {
    .ant-pagination {
      margin: 0 -40px;
      align-items: center;
      justify-content: flex-end;
      background: #fff;
      border-top: 1px solid #ededed;
      padding: 16px 72px 16px 140px;
      position: fixed;
      bottom: 0;
      width: 100%;
      right: 0;
      width: calc(100% - 200px);
      transition: width 0.2s;
    }
  }
  .admin-pagination {
    position: fixed;
    bottom: 0;
    left: 32px;
    z-index: 999;
    left: calc(240px + 32px);
    font-family: $font-major;
    color: $dark-900;
    font-size: 14px;
    height: 65px;
    line-height: 65px;
    transition: left 0.2s;
  }
  .site-layout.collapsed {
    .ant-pagination {
      right: 0;
      width: calc(100% - 40px);
    }
    .admin-pagination {
      left: calc(80px + 32px);
    }
    .site-content .admin-header {
      width: calc(100% - 80px);
    }
    .analytics.site-content .admin-header {
      width: calc(100% - 320px);
    }
  }
}

#masq {
  line-height: 16px;
  padding: 8px;
  background: #f9d2ce;
  border: 1px solid #f9d2ce;
  z-index: 99999999;
  text-align: center;
  font-family: $font-main;
  color: $dark-900;
  font-size: 12px;
  margin: 16px;
  border-radius: 8px;
  b {
    font-weight: 600;
  }
  > span {
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
  }
}
