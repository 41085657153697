@import "/src/_styleguide/variables.scss";

.settings-modal {
  .ant-modal-content {
    height: 80vh;
    max-height: 760px;
  }
  .ant-modal-body {
    padding: 0 !important;
    height: 100%;
  }
  .barn-modal-body {
    padding: 0 !important;
    margin: 0 !important;
    display: flex;
    height: calc(100% - 60px);
    .sm-page {
      width: calc(100% - 32px);
      display: block;
      position: relative;
      margin: 12px 16px;
      max-height: 100%;
      overflow: auto;
    }
  }
  .barn-modal-close {
    display: flex;
    height: 32px;
    width: 32px;
    justify-content: center;
    align-items: center;
    gap: 2px;
    border-radius: 42px;
    border: 1px solid #d9d9d9;
    top: 14px;
    right: 16px;
    background: #fff;
    box-shadow: 1px 1px 0px 0px #f3f3f3;
    &:hover {
      border-color: #000;
    }
  }
  .settings-search {
    display: flex;
    padding: 10px 12px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 10px 10px 0px 0px;
    border-top: 1px solid #ececec;

    border-right: 1px solid #ececec;

    border-left: 1px solid #ececec;

    background: #fff;
    .ms-filters {
      .ant-input-group-wrapper {
        max-width: 240px;
      }
    }
    .ms-actions {
      button {
        font-family: $font-title;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;
        border-radius: 10px !important;
        svg {
          width: 14px;
          height: 14px;
          color: #757575 !important;
        }
      }
    }
  }
  .settings-title {
    display: flex;
    padding: 16px 16px 12px 16px;
    justify-content: flex-start;
    align-items: center;
    align-self: stretch;
    border: 1px solid #ececec;
    gap: 12px;
    .st-icon {
      display: flex;
      width: 28px;
      height: 28px;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      border: 1px solid #d6d6d6;
      background: #fff;
      box-shadow:
        0px 7.143px 4.286px 0px rgba(0, 0, 0, 0.03),
        0px 2.857px 2.857px 0px rgba(0, 0, 0, 0.04),
        0px 1.429px 1.429px 0px rgba(0, 0, 0, 0.05);

      svg {
        width: 16px;
        height: 16px;
        color: #000;
      }
    }
    h4 {
      color: #000;
      font-family: $font-title;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 30px;
      margin: 0;
    }
  }
}
