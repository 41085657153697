@import "/src/_styleguide/variables.scss";

.confirm-iw {
  button {
    margin-top: 24px;
    color: #000 !important;
    font-family: $font-title;
    font-weight: 500;
  }
  p.final-terms {
    color: #000;
    font-family: $font-title;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin: 0;
    b {
      font-weight: 500;
    }
    a {
      font-weight: 500;
      color: #000;
    }
  }
  .pp-terms-scroll {
    display: block;
    max-height: 320px;
    padding: 16px 12px 16px 16px;
    border-radius: 8px;
    border: 1px solid #e8e3d8;
    overflow: scroll;
    margin-bottom: 16px;
    background: #f8f6f2;
    font-family: $font-title;

    p {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      color: #000;
      b {
        font-weight: 500;
      }
    }

    table,
    th,
    td {
      border: 1px solid black;
      border-collapse: collapse;
    }

    table {
      width: 100%;
      th,
      td {
        text-align: left;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        color: #000;
        padding: 0 4px;
      }
      th {
        font-weight: 500;
      }
    }

    a {
      font-weight: 500;
      color: #000;
    }
    > h2 {
      margin-top: 0;
      font-family: $font-title;
    }
    > h3 {
      margin-top: 0;
      font-family: $font-title;
    }
  }
}
