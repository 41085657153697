@import "/src/~@centrate-io/barn/dist/variables";

.admin-application-credit-reports {
  .cl-link {
    color: $dark-1000;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
