@import "/src/~@centrate-io/barn/dist/variables";

.auth-two-factor {
  min-width: 240px;
  width: 320px;
  padding: 40px;
  border-radius: 24px;
  background: #fff;
  border: 1px solid #eeedec;
  box-shadow: 0 0 100px -30px rgba(0, 0, 0, 0.1);
  .line {
    margin-bottom: 20px;
  }
  button {
    margin-top: 32px;
    font-weight: 600;
  }
  .auth-info {
    text-align: center;
    margin-bottom: 32px;
  }
  .login-logo {
    height: 28px;
  }
  .forgot {
    text-align: center;
    a {
      text-decoration: none;
    }
  }
  .channel {
    text-align: center;
    margin-top: 24px;
    svg {
      width: 14px;
      height: 14px;
      position: relative;
      top: 2px;
      margin-left: 4px;
    }
    a {
      text-decoration: none;
    }
  }
}

.signout {
  position: fixed;
  top: 32px;
  right: 32px;
}
